import React, { useEffect, useState } from 'react';
import { FaHeart, FaComment } from 'react-icons/fa';
import axios from 'axios';
import { io } from 'socket.io-client';
import { assets } from '../assetes/index';

const socket = io('https://dattatreya-back.onrender.com/');

const Dashboard = () => {
  const [content, setContent] = useState({ blogs: [], videos: [] });
  const [likedVideos, setLikedVideos] = useState(new Set());
  const [commentText, setCommentText] = useState({});
  const [comments, setComments] = useState({});
  const [showComments, setShowComments] = useState({});

  useEffect(() => {
    socket.on('newComment', ({ videoId }) => {
      handleGetComments(videoId);
    });

    return () => {
      socket.off('newComment');
    };
  }, []);

  const handleLike = async (videoId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('User not authenticated');
        return;
      }

      const response = await axios.post(
        'https://dattatreya-back.onrender.com/api/videos/like',
        { videoId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setContent((prevContent) => ({
          ...prevContent,
          videos: prevContent.videos.map((video) =>
            video._id === videoId ? { ...video, likes: response.data.likes } : video
          ),
        }));
        setLikedVideos((prevLikedVideos) => new Set(prevLikedVideos).add(videoId));
      }
    } catch (error) {
      console.error('Error liking the video:', error.response?.data?.message || error.message);
    }
  };

  const handleComment = async (videoId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('User not authenticated');
        return;
      }

      await axios.post(
        `https://dattatreya-back.onrender.com/api/videos/comments`,
        { text: commentText[videoId] || '', videoId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCommentText((prev) => ({ ...prev, [videoId]: '' })); 
      socket.emit('sendComment', { videoId });

      handleGetComments(videoId);
    } catch (error) {
      console.error('Error commenting on the video:', error.response?.data?.message || error.message);
    }
  };

  const handleGetComments = async (videoId) => {
    try {
      const token = localStorage.getItem('token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await axios.get(`https://dattatreya-back.onrender.com/api/commentget/${videoId}`, { headers });

      console.log(response.data);

      if (response.data?.comments) {
        setComments((prevComments) => ({
          ...prevComments,
          [videoId]: response.data.comments.map(comment => ({
            _id: comment._id,
            text: comment.text,
            createdAt: new Date(comment.createdAt).toLocaleString(),
            commentedBy: comment.commentedBy?.profiledetails?.Username || 'Unknown User'
          })),
        }));
      } else {
        console.warn("No comments found.");
      }
    } catch (error) {
      console.error("Error fetching comments:", error.message);
    }
  };

  const toggleComments = (videoId) => {
    setShowComments((prev) => ({
      ...prev,
      [videoId]: !prev[videoId],
    }));
    
    if (!comments[videoId]) {
      handleGetComments(videoId);
    }
  };

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found, please login');
          return;
        }

        const response = await axios.get(
          'https://dattatreya-back.onrender.com/api/userprofile/Dashcontent',
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setContent(response.data.data);

          const likedVideoIds = new Set(
            response.data.data.videos.filter((video) => video.userHasLiked).map((video) => video._id)
          );
          setLikedVideos(likedVideoIds);
        } else {
          console.error('Error fetching content:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching content:', error.response?.data?.message || error.message);
      }
    };

    fetchContent();
  }, []);

  return (
    <div className="flex h-screen bg-gray-100 overflow-auto">
      <div className="flex-1 p-6">
        {content.videos.length > 0 ? (
          content.videos.map((video) => (
            <div key={video._id} className="bg-white p-6 rounded-lg shadow-lg mb-6">
              <div className="flex items-center mb-4">
                <img
                  src={video.uploadedBy?.profiledetails?.profilePic || assets.defaultAvatar}
                  alt="Avatar"
                  className="w-10 h-10 rounded-full mr-4"
                />
                <h2 className="font-semibold text-gray-800">
                  {video.uploadedBy?.profiledetails?.Username || 'Unknown User'}
                </h2>
                {video.uploadedBy?.verified && (
                  <img src={assets.Badge} alt="Verified Badge" className="inline-block ml-1 mt-2 w-8 h-9" />
                )}
              </div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">{video.title}</h3>
              <video src={video.videoUrl} controls className="w-full rounded-lg" />
              <p className="text-gray-700 mb-4">{video.description}</p>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => handleLike(video._id)}
                  className={`flex items-center gap-2 transition ${
                    likedVideos.has(video._id) ? 'text-red-500' : 'text-gray-600 hover:text-red-500'
                  }`}
                >
                  <FaHeart />
                  <span>{video.likes} Like{video.likes !== 1 ? 's' : ''}</span>
                </button>
                <button
                  onClick={() => toggleComments(video._id)}
                  className="flex items-center gap-2 text-gray-600 hover:text-blue-500 transition"
                >
                  <FaComment />
                  <span>Comments</span>
                </button>
              </div>

              {showComments[video._id] && (
                <div className="mt-4">
                  <input
                    type="text"
                    value={commentText[video._id] || ''}
                    onChange={(e) => setCommentText((prev) => ({ ...prev, [video._id]: e.target.value }))}
                    placeholder="Add a comment..."
                    className="border p-2 rounded w-full"
                  />
                  <button onClick={() => handleComment(video._id)} className="mt-2 bg-blue-500 text-white p-2 rounded">
                    Comment
                  </button>
                  <div className="mt-4">
                    {comments[video._id]?.map((comment) => (
                      <p key={comment._id} className="text-gray-700 mt-2">
                        <strong>{comment.commentedBy}:</strong> {comment.text}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-700">No videos available.</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
